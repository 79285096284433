import React from 'react';

/* import {StaticImage} from 'gatsby-plugin-image'; */

import Layout from '../components/Layout';

export default function WerWirSind() {
	return (
		<Layout title="Wer wir sind">
			<h2>Wer wir sind</h2>
			<p>Die Bereitschaft im Bayerischen Roten Kreuz (BRK) ist eine ehrenamtliche Gemeinschaft.</p>
			<p>
				Unsere Tätigkeiten umfassen den Sanitätsdienst bei Sport- und Großveranstaltungen, Altkleidersammlungen,
				Unterstützung des Blutspendedienstes, Betreuung von Ausflügen mit Senioren und Menschen mit Behinderung,
				sowie den Katastrophenschutz (SEG).
			</p>
			<h4>Unsere Bereitschaftsleitung</h4>
			{/* <p>
					<StaticImage
					src="../images/wer-wir-sind/Bereitschaftsleitung-Alt.jpg"
					alt="Foto Bereitschaftsleitung"
					width={600}
					/>
					</p> */}
			<p>
				<b>Bereitschaftsleiter:</b>
				<br />
				{/* Florian Halter (Bild mitte) */}
				Florian Halter
			</p>
			<p>
				<b>Stellvertretende Bereitschaftsleiterin:</b>
				<br />
				Angela Gabriel
			</p>
			<p>
				<b>Stellvertretender Bereitschaftsleiter:</b>
				<br />
				Sebastian Luderschmidt
			</p>
			<h4>Funktionen in der Schnell-Einsatz-Gruppe (SEG)</h4>
			<p>
				<b>SEG-Leiter:</b>
				<br />
				Jakob Gafus
			</p>
			<p>
				<b>Stellvertretender SEG-Leiter:</b>
				<br />
				Bernhard Reiser
			</p>
			<p>
				<b>SEG-Gruppenführer:</b>
				<br />
				Christopher Löffelmann
				<br />
				Peter Forstner
				<br />
				Thomas Fritzer
			</p>
		</Layout>
	);
}
